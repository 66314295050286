import "../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js";
import * as AOS from "../../assets/libs/aos.js";
import Footer from "@/components/footer/Footer";
import Header from "@/components/header/header";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import pageBreadcrumb from "@/components/pageBreadcrumb/pageBreadcrumb";
import "swiper/swiper-bundle.min.css";
import Toolbar from "@/components/toolbar/toolbar";

export default {
  name: "Search",
  data() {
    return {
      searchQuery: null,
      result: null,
      ListItem: [],
    };
  },
  methods: {
    async fetchSearch(search) {
      this.result = search;
      await this.$axios.get(`/api/show/search/${search}`).then((response) => {
        if (response.data.code == 200) {
          this.ListItem = response.data.data.item;
        }
      });
    },
  },
  async mounted() {
    document.title = "AGC - Search";
    AOS.init();
    if (this.$route.query.q) {
      this.searchQuery = this.$route.query.q;
      this.fetchSearch(this.searchQuery);
    }
  },
  components: {
    Footer,
    Header,
    Breadcrumb,
    Toolbar,
    pageBreadcrumb,
  },
};
